<template>
  <div class="page-option-bar">
    <span>
      <el-select
        v-model="spiderId"
        placeholder="请选择爬虫"
        @change="handleChangeSpider"
      >
        <el-option label="所有数据" :value="null"></el-option>
        <el-option
          v-for="item in projectSpiderList"
          :key="item.spiderUid"
          :label="item.spiderVersionName"
          :value="item.spiderUid"
        ></el-option>
      </el-select>
    </span>
    <span>
      <el-button @click="handleBatchCommand('batchDelete')" type="default">
        批量删除
      </el-button>
    </span>
    <span>
      <el-button type="primary" @click="handleBatchCommand('batchExport')">
        批量导出
      </el-button>
    </span>
  </div>
  <div class="data-page" v-loading="pageLoading">
    <div class="list-table" v-if="ifShowTable">
      <div class="option-bar">
        <div class="search-group">
          <el-form
            class="base-search"
            label-position="right"
            label-width="88px"
          >
            <div class="form-inline">
              <el-form-item prop="taskId" label="任务ID：">
                <el-select
                  v-model="searchForm.taskId"
                  placeholder="请选择任务ID"
                  clearable
                  filterable
                  @clear="handleClickClear"
                >
                  <el-option
                    v-for="item in taskIdOptions"
                    :key="item.taskUid"
                    :label="item.taskNumber"
                    :value="item.taskUid"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="time" label="采集时间：" class="search-time">
                <el-date-picker
                  v-model="searchForm.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  clearable
                  @clear="handleClickClear"
                />
              </el-form-item>
            </div>
            <!-- <el-form-item
              prop="column"
              label="字段名称："
              class="search-column"
            >
              <el-select
                v-model="searchForm.column"
                placeholder="请选择字段"
                @change="handleChangeColumn"
                clearable
                @clear="handleClickClear"
              >
                <el-option
                  v-for="item in dataColumn"
                  :key="item.name"
                  :label="item.label"
                  :value="item.name"
                />
              </el-select>
              <el-input
                v-model="searchForm.keyword"
                v-if="showInput"
                placeholder="请输入"
                clearable
                @clear="handleClickClear"
              />
              <el-date-picker
                v-model="searchForm.keyword"
                v-else
                type="date"
                placeholder="请选择"
                clearable
                @clear="handleClickClear"
              />
            </el-form-item> -->
          </el-form>
          <!-- <FilterConditionsForm
            v-model:data="searchConditionForm"
            :variableList="dataColumn"
            @submit="getDataList"
          /> -->
          <div class="search-button">
            <el-button type="primary" @click="getDataList">查询</el-button>
            <el-button class="button-plain" @click="handleClickReset">
              清空
            </el-button>
          </div>
        </div>
      </div>
      <div class="view-wrapper">
        <div class="view-header">
          <el-radio-group type="button" v-model="pageType" size="small">
            <el-radio-button :label="PAGE_TYPE.TABLE">列表视图</el-radio-button>
            <el-radio-button :label="PAGE_TYPE.DOCUMENT">
              文档视图
            </el-radio-button>
          </el-radio-group>
        </div>
        <component
          :is="currentComponent"
          :class="
            pageType === PAGE_TYPE.TABLE ? 'table-wrapper' : 'document-wrapper'
          "
          :dataColumn="dataColumn"
          :dataList="dataList"
          :loading="loading"
          @refresh="getDataList"
          @select-data="handleSelectionChange"
          @delete-data="handleClickDelete"
        ></component>
        <div class="pagination-box">
          <el-pagination
            v-model:current-page="pageNum"
            v-model:page-size="pageSize"
            small
            background
            layout="total, prev, pager, next, sizes"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="goToPage"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="empty" v-if="!pageLoading && !ifShowTable">
      <EmptyStatus :size="350" :imageSrc="require('@/assets/imgs/empty2.png')">
        <template #description>
          <div>暂无数据</div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { Search } from "@element-plus/icons-vue";
import { httpPost, baseUrl } from "@/api/httpService.js";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { usePagination } from "@/utils/usePagination";
import { Debounce } from "@/utils/utils.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { export2Excel } from "@/utils/excel.js";
import { timeFormatter } from "@/utils/time.js";
import DataDocumentView from "./DataDocumentView.vue";
import DataTableView from "./DataTableView.vue";
// import FilterConditionsForm from "@/components/filterConditionsForm/FilterConditionsForm.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { remindErrorMessage, objectToUrlParams } from "@/utils/utils";
import { downloadFile } from "@/utils/downloadFile";

const router = useRouter();
const route = useRoute();
const projectId = ref(route.params.projectId);

const spiderId = ref(null); //项目爬虫版本id
const projectSpiderList = ref([]);
const getProjectSpiderList = async () => {
  let params = {
    projectUid: projectId.value,
  };
  let res = await httpPost("/projectSpider/v1/searchSpiderVersion", params);
  if (res.code == 0) {
    projectSpiderList.value = res.data;
  }
};

const handleChangeSpider = () => {
  clearSearchForm();
  getDataList();
  getTaskList();
};

// #region 筛选相关
// const searchTaskId = ref("");
// const searchTime = ref(null);
/**
 *   {
    id: 1,
    relation: "",
    column: "",
    type: "",
    keyword: "",
  },
 */
const searchConditionForm = ref([
  {
    id: 1,
    relation: null,
    column: null,
    type: null,
    keyword: null,
  },
]);
const taskIdOptions = ref([]);
const getTaskList = async () => {
  let params = {
    projectUid: projectId.value,
    projectSpiderUid: spiderId.value,
  };
  let res = await httpPost("/task/v1/searchProjectTaskNumber", params);
  if (res.code == 0) {
    taskIdOptions.value = res.data;
  }
};
const searchForm = ref({
  taskId: null,
  time: null,
  column: "",
  keyword: "",
});
const ifShowTable = ref(true);
//是否无筛选
const checkSearchForm = computed(() => {
  if (
    !searchForm.value.time &&
    searchForm.value.taskId === "" &&
    searchForm.value.column === "" &&
    searchForm.value.keyword === ""
  ) {
    return false;
  } else {
    return true;
  }
});
//变量切换
const handleChangeColumn = () => {
  searchForm.value.keyword = "";
};
//是否显示输入框
const showInput = computed(() => {
  let type = columnTypeMap.value[searchForm.value.column];
  if (type == "datetime") {
    return false;
  } else {
    return true;
  }
});
const clearSearchForm = () => {
  searchForm.value.taskId = null;
  searchForm.value.time = null;
  searchForm.value.column = "";
  searchForm.value.keyword = "";
};

//清空
const handleClickReset = async () => {
  clearSearchForm();
  // await getDataColumn();
  await getDataList();
};
const handleClickClear = async () => {
  if (
    !searchForm.value.time &&
    searchForm.value.taskId === "" &&
    searchForm.value.column === "" &&
    searchForm.value.keyword === ""
  ) {
    // await getDataColumn();
  }
};
// #endregion 筛选相关

// #region 数据相关
const dataList = ref([]);
const dataColumn = ref([]);
const loading = ref(false);
const pageLoading = ref(false);
const columnTypeMap = ref({});
const getDataList = async () => {
  loading.value = true;
  // let params = {
  //   pageNum: pageNum.value,
  //   pageSize: pageSize.value,
  //   projectId: projectId.value,
  //   taskId: searchForm.value.taskId,
  //   startTime: searchForm.value.time
  //     ? timeFormatter(searchForm.value.time[0])
  //     : null,
  //   endTime: searchForm.value.time
  //     ? timeFormatter(searchForm.value.time[1])
  //     : null,
  //   columnName: searchForm.value.column, //所选字段
  //   keyword: searchForm.value.keyword, //关键词
  //   missingData: 0, //缺值数据（1、0）
  // };
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    projectUid: projectId.value,
    taskUid: searchForm.value.taskId || null,
    beginTime: searchForm.value.time
      ? timeFormatter(searchForm.value.time[0])
      : null,
    endTime: searchForm.value.time
      ? timeFormatter(searchForm.value.time[1])
      : null,
    projectSpiderUid: spiderId.value,
    // TODO 拼接条件待完成
    // conditions: searchConditionForm.value,
    // conditions: [
    //   // {
    //   //   type: 1,
    //   //   keyword: searchForm.value.keyword,
    //   //   column: searchForm.value.column, //所选字段
    //   //   relation: 0,
    //   // },
    // ],
  };
  // let res = await httpPost("/project/data/v1/list", params);
  let res = await httpPost("/task/v1/searchDataList", params);
  if (res.code == 0) {
    dataList.value = res.data.data.data;
    dataColumn.value = res.data.columns;
    //生成字段类型映射
    dataColumn.value?.forEach((item) => {
      columnTypeMap.value[item.name] = item.type;
    });
    pageNum.value = res.data.data.pageNum;
    pageSize.value = res.data.data.pageSize;
    total.value = res.data.data.total;
    if (total.value == 0 && !checkSearchForm.value) {
      ifShowTable.value = false;
    } else {
      ifShowTable.value = true;
    }
  }
  loading.value = false;
  pageLoading.value = false;
};
//获取数据列信息
const getDataColumn = async () => {
  pageLoading.value = true;

  let res = await httpPost("/task/v1/searchDataFilterColumns", {
    projectUid: projectId.value,
  });
  if (res.code == 0) {
    dataColumn.value = res.data;
    await getDataList();
  }
  pageLoading.value = false;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getDataList,
  ifFetchOnMounted: false,
});
console.log("page", pageNum, pageSize, total);
// #endregion 数据相关

// #region 批量操作相关
// 记录列表勾选后被选中的id
const selectedItemList = ref([]);
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
const handleBatchCommand = (command) => {
  switch (command) {
    case "batchDelete":
      if (selectedItemList.value.length == 0) {
        ElMessage.warning("请先勾选数据！");
        return;
      }
      handleClickBatchDelete();
      break;
    case "batchExport":
      handleClickBatchExport();
      break;
  }
};
// 批量删除
const handleClickBatchDelete = () => {
  confirmDeleteBox("此操作将永久删除所选数据, 是否继续?")
    .then(async () => {
      let idList = [];

      for (let i = 0; i < selectedItemList.value.length; i++) {
        idList.push(selectedItemList.value[i]._id);
      }

      let params = {
        dataIds: idList,
        projectUid: projectId.value,
      };
      let res = await httpPost("/task/v1/deleteBatchData", params);
      if (res.code == 0) {
        getDataList();
        ElMessage.success("删除成功");
      } else {
        remindErrorMessage(res.message);
      }
    })
    .catch(() => {});
};
// 批量导出
const handleClickBatchExport = () => {
  confirmDeleteBox("此操作将导出数据, 是否继续?").then(async () => {
    let params = {
      dataIds: selectedItemList.value.map((item) => item._id),
      projectUid: projectId.value,
      taskUid: searchForm.value.taskId,
      beginTime: searchForm.value.time
        ? timeFormatter(searchForm.value.time[0])
        : null,
      endTime: searchForm.value.time
        ? timeFormatter(searchForm.value.time[1])
        : null,
      projectSpiderUid: spiderId.value,
    };
    // console.log("🚀 ~ confirmDeleteBox ~ params:", params);
    let url = "/task/v1/exportDataExcel?" + objectToUrlParams(params);
    // console.log("🚀 ~ confirmDeleteBox ~ url:", url);
    downloadFile(url);
  });
};
// #endregion 批量操作相关

// #region 单个数据操作相关
//删除
const handleClickDelete = (row) => {
  confirmDeleteBox("此操作将永久删除所选数据, 是否继续?").then(async () => {
    let res = await httpPost("/collection/task/v1/deleteData", {
      projectUid: projectId.value,
      dataIds: [row._id],
    });
    if (res.code == 0) {
      getDataList();
      ElMessage.success("删除成功");
    } else {
      remindErrorMessage(res.message);
    }
  });
};
// #endregion 单个项目操作相关

//页面类型
const pageType = ref(0);
const PAGE_TYPE = {
  TABLE: 0,
  DOCUMENT: 1,
};
const currentComponent = computed(() => {
  if (pageType.value == PAGE_TYPE.TABLE) {
    return DataTableView;
  } else {
    return DataDocumentView;
  }
});
onMounted(async () => {
  await getProjectSpiderList();
  spiderId.value = projectSpiderList.value?.[0].spiderUid;
  if (route.query.taskId) {
    searchForm.value.taskId = route.query.taskId;
    spiderId.value = null;
  }

  // await getDataColumn();
  await getDataList();
  await getTaskList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
$search-button-width: 132px;
$condition-button-width: 44px;
.data-page {
  min-height: $project-page-min-height;
}
.page-option-bar {
  top: -75px;
  right: 0px;
}
.button-plain {
  background-color: #ffffff;
  border-color: $theme-color;
  color: $theme-color;
}
.option-bar {
  margin-bottom: 0 !important;
  .search-group {
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    .base-search {
      // width: calc(100% - $search-button-width - $condition-button-width - 40px);
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // :deep(.el-date-editor) {
      //   width: 260px;
      // }
      // :deep(.el-form-item) {
      //   margin-right: 0px;
      // }
      // :deep(.el-form-item__label) {
      //   margin-right: calc(20px - 12px);
      // }
      .form-inline {
        display: flex;
        /* justify-content: space-between; */
        :deep(.el-select) {
          margin-right: 20px;
          .select-trigger {
            width: 150px;
          }
        }
      }
      .search-column {
        margin-bottom: 0px;
        :deep(.el-form-item__content) {
          display: flex;
          flex-wrap: nowrap;
        }
        :deep(.el-select) {
          margin-right: 20px;
          .select-trigger {
            width: 150px;
          }
        }
      }
    }
    .search-button {
      white-space: nowrap;
    }
  }
}

.view-wrapper {
  .view-header {
    margin-bottom: 20px;
    // width: 100%;
    // overflow: hidden;
    height: 32px;
    .el-radio-group {
      float: right;
      border-radius: 36px;
      box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
      .el-radio-button {
        :deep(.el-radio-button__inner) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 32px;
          border: 0px;
          border-radius: 36px;
        }
      }
      .is-active {
        :deep(.el-radio-button__inner) {
          display: flex;
          align-items: center;
          height: 32px;
          border-radius: 36px;
        }
      }
    }
  }
  .table-wrapper {
  }
  .document-wrapper {
  }
}

.empty {
  height: $project-page-min-height;
}
</style>
