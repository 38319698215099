<template>
  <div>

    <div v-if="!ifShowNoPermission">
      <div class="page-option-bar">
        <span>
          <!-- <el-button type="primary" @click="handleClickCreateStrategy">
          新建策略
        </el-button> -->
          <el-button type="primary" @click="openScheduleDialog">
            关联策略
          </el-button>
        </span>
      </div>
      <ScheduleCardRelateList
        :data="relatedScheduleList"
        :loading="ifShowLoading"
        @edit="onEditSchedule"
        @delete="onDeleteSchedule"
        @enable="onEnableSchedule"
        @pause="onPauseSchedule"
        @relate="openScheduleDialog"
        @view="onViewSchedule"
      />
      <!-- <div v-else>
        <EmptyStatus
          :imageSrc="require('@/assets/imgs/empty2.png')"
          :size="350"
        >
          <template #description>
            <div style="padding-bottom: 10px">
              <span>暂无策略，立即</span>
              <span
                class="description-button"
                style="font-size: 16px"
                @click="openScheduleDialog"
              >
                关联策略
              </span>
            </div>
            <div style="font-size: 16px">
              策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
            </div>
          </template>
        </EmptyStatus>
      </div> -->
      <ScheduleDrawerManage
        ref="scheduleDialogManageRef"
      ></ScheduleDrawerManage>
      <!-- <StrategyCard /> -->
      <ScheduleDialogRelate
        ref="scheduleDialogLinkRef"
        :AllScheduleCardDataList="AllScheduleCardDataList"
        @onSelectSchedule="onSelectSchedule"
      />
    </div>
    <div v-if="!ifShowLoading && ifShowNoPermission" class="empty-container">
      <EmptyStatus :imageSrc="require('@/assets/imgs/empty2.png')" :size="350">
        <template #description>
          <div style="font-size: 18px; margin-bottom: 12px">
            {{ ROLE_MAP[role]?.label }}暂无策略的使用权限，您可
            <span class="description-button" @click="goPricing">升级版本</span>
            ，体验采集任务的策略个性化配置。
          </div>
          <div style="font-size: 16px">
            策略用来设置采集项目的执行时间，比如可以设置项目定时采集、循环采集等。
          </div>
        </template>
      </EmptyStatus>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed,
  watch,
} from "vue";
import ScheduleDialogRelate from "./ScheduleDialogRelate.vue";
import ScheduleCardRelateList from "./ScheduleCardRelateList.vue";
// import ScheduleDialogManage from "./ScheduleDialogManage.vue";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import VueCookies from "vue-cookies";
import { ROLE, ROLE_MAP } from "@/constant/role.js";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import { useRouter, useRoute } from "vue-router";
import { usePagination } from "@/utils/usePagination";
import ScheduleDrawerManage from "@/views/scheduleCenter/components/ScheduleDrawerManage.vue";
// import { StrategyCardList } from '@/views/strategyCenter/components/StrategyCardList.vue'
// import StrategyCard from './StrategyCard.vue'
// import StrategyDialogManage from "./StrategyDialogManage.vue";

// const strategyDialogManageRef = ref(null);
// const handleClickCreateStrategy = () => {
//   strategyDialogManageRef.value.openDLG({ type: "CREATE" });
// };
const projectUid = ref("");
const route = useRoute();
watch(
  () => route.params,
  (newVal, oldVal) => {
    projectUid.value = newVal.projectId;
  },
  { immediate: true, deep: true }
);
const scheduleDialogLinkRef = ref(null);
const router = useRouter();
const AllScheduleCardDataList = ref([]);
const scheduleDialogManageRef = ref(null);
const getScheduleList = async () => {
  if (role.value === ROLE.TRIAL || role.value === ROLE.STANDARD) {
    ifShowLoading.value = false;

    return;
  }
  ifShowLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  // let res = await httpPost("/schedule/v1/getScheduleList");
  let res = await httpPost("/schedule/v1/pageSchedule", params);
  if (res.code == 0) {
    AllScheduleCardDataList.value = res.data.data;
    ifShowLoading.value = false;
    console.log("🚀 ~ getScheduleList ~ ifShowLoading:", ifShowLoading);
  }
};
const ifShowLoading = ref(true);

const relatedScheduleList = ref([]);
// 有无策略
const role = ref(VueCookies.get("userInfo")?.spiderPermission?.role);
// TODO 测试用权限
// role.value = 3;
const ifShowNoPermission = computed(() => {
  return role.value != ROLE.PREMIUM && role.value != ROLE.CUSTOMIZED;
});
// 是否为体验版
// const ifExperienceVersion = ref(false);
//关联弹窗中选择策略进行关联
const onSelectSchedule = async (selectedSchedule) => {
  const res = await selectSchedule(selectedSchedule);
  if (res.code == 0) {
    getScheduleRelatedList();
    ElMessage.success("关联成功");
  } else {
    ElMessage.warning("关联失败");
  }
};
// 选择策略
const selectSchedule = async (selectedSchedule) => {
  const params = {
    scheduleUidList: [selectedSchedule.scheduleUid],
    projectUidList: [projectUid.value],
  };
  let res = await httpPost("/project/v1/batchAssociationSchedule", params);
  if (res.code == 0) {
    return res;
  }
};
// 根据projectUid获取项目关联的策略
const getRelatedScheduleList = async () => {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    projectUid: projectUid.value,
  };
  let res = await httpPost("/project/v1/searchScheduleInProject", params);
  return res;
};
// 查看策略详情
const onViewSchedule = (schedule) => {
  scheduleDialogManageRef.value.openDrawer({ type: "VIEW", schedule });
};
// 待修改-获取项目关联的策略列表
const getScheduleRelatedList = async () => {
  if (role.value === ROLE.TRIAL || role.value === ROLE.STANDARD) return;
  // ifShowLoading.value = true;

  const { code, data } = await getRelatedScheduleList();
  if (code === 0) {
    // ifShowLoading.value = false;

    relatedScheduleList.value = data.data;
  }
};

//打开策略弹窗
const openScheduleDialog = () => {
  scheduleDialogLinkRef.value.openDLG();
};
const onEditSchedule = (schedule) => {
  console.log(scheduleDialogManageRef.value);
  scheduleDialogManageRef.value.openDrawer({ type: "EDIT", schedule });
  console.log("🚀 ~ onEditSchedule ~ schedule:", schedule);
};
// 检查策略是否关联项目
const checkIfScheduleAssociateWithProject = async (scheduleList) => {
  let params = {
    scheduleUidList: [scheduleList.scheduleUid],
  };
  let res = httpPost("/schedule/v1/isScheduleAssociatedWithProject", params);
  if (res.code == 0) {
    return res.data.some((item) => item.isAssociated);
  }
};

//删除策略接口
const deleteSchedule = async (scheduleList) => {
  let params = {
    projectUid: projectUid.value,
    scheduleUid: scheduleList.scheduleUid,
  };
  let res = await httpPost("/project/v1/deleteScheduleInProject", params);
  return res;
};
// 触发移除策略-解除关联
const onDeleteSchedule = async (scheduleList) => {
  // let checkResult = await checkIfScheduleAssociateWithProject(scheduleList);
  confirmDeleteBox("确认删除吗？").then(async () => {
    let res = await deleteSchedule(scheduleList);
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getScheduleRelatedList();
    } else {
    }
  });
};
// 触发启用策略
const onEnableSchedule = async (scheduleList) => {
  enableSchedule(scheduleList);
};
// 启用策略
const enableSchedule = async (scheduleList) => {
  // let checkResult = await checkIfScheduleAssociateWithProject(scheduleList);
  confirmDeleteBox("确认启用吗？").then(async () => {
    let params = {
      scheduleUidList: [scheduleList.scheduleUid],
    };
    let res = await httpPost("/schedule/v1/enableSchedule", params);
    if (res.code == 0) {
      ElMessage.success("启用成功");
      getScheduleRelatedList();
    } else {
    }
  });
};
// 触发暂停策略
const onPauseSchedule = async (scheduleList) => {
  // let checkResult = await checkIfScheduleAssociateWithProject(scheduleList);
  confirmDeleteBox("确认暂停吗？").then(async () => {
    let res = await pauseSchedule(scheduleList);
    if (res.code == 0) {
      ElMessage.success("暂停成功");
      getScheduleRelatedList();
    } else {
    }
  });
};
const pauseSchedule = async (scheduleList) => {
  let params = {
    scheduleUidList: [scheduleList.scheduleUid],
  };
  let res = httpPost("/schedule/v1/suspendSchedule", params);
  return res;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getScheduleList,
  ifFetchOnMounted: false,
});
// 版本升级，体验版 -> 高级版
const goPricing = () => {
  router.push({ name: "Pricing" });
};
const getRole = async () => {
  let res = await httpPost("/user/v1/getUserPermission", null);
  if (res?.code == 0) {
    let userInfo = VueCookies.get("userInfo") || {};
    userInfo.spiderPermission = res.data;
    userInfo.ifLoginSpider = true;
    VueCookies.set("userInfo", JSON.stringify(userInfo));
    role.value = res.data.role;
  }
};
onMounted(async () => {
  if (!role.value) {
    await getRole();
  }
  getScheduleList();
  getScheduleRelatedList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
.page-option-bar {
  top: -75px;
  right: 20px;
}
</style>
